export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0x19bc84b73f3EF11489732972A1986043087bfd84";

export const artistId = 3539;
export const artistAddress = "0xDE8f5F0b94134d50ad7f85EF02b9771203F939E5";

export const projectName = "Parallax";
export const projectLink = "https://parallax.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1715616000000;

export const artistName = "Socmplxd";
export const bio = [
  "SOCMPLXD is a multidisciplinary artist with experience working in film, illustration, and fine art. He explores the mundane aspects of everyday life through his work, drawing inspiration from his upbringing living in Asia and the United States.",
  "SOCMPLXD's use of staging and lighting, developed from his background as a set designer, is prominent in his latest series. The simplicity, precision, and crispness of his graphic shapes are a hallmark of his digital art style.",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@socmplxd";
export const artistTwitter = "https://twitter.com/socmplxdart";
export const artistInsta = "";

export const interviewPath = "/interview/A-conversation-with-SOCMPLXD-3";
export const articlePath = "";
export const standard = "ERC-721";
export const mintedOn = "Manifold";
export const totalPieces = "8";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
