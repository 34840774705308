import "./App.css";
import Routers from "./Routes";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import { useEffect, useState } from "react";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { mainnet, base } from "viem/chains";
import { projectName, artistName, projectLink } from "./constants";

import mouseImage from "./assets/mouse.png";

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

if (!projectId) {
  throw new Error(
    "REACT_APP_WALLETCONNECT_PROJECT_ID is not set in the environment variables"
  );
}

// Create wagmiConfig
const metadata = {
  name: projectName,
  description: "A Project by " + artistName,
  url: projectLink,
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  const [ready, setReady] = useState(false);

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      {ready ? (
        <ThemeProvider theme={theme}>
          <WagmiConfig config={wagmiConfig}>
            <img
              src={mouseImage}
              alt="mouse"
              style={{
                position: "fixed",
                top: mousePosition.y - 10,
                left: mousePosition.x - 10,
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                pointerEvents: "none",
                zIndex: 1000000,
                // animation: "breathe 2s linear infinite",
              }}
            />
            <Routers />
          </WagmiConfig>
        </ThemeProvider>
      ) : null}
    </>
  );
}

export default App;
